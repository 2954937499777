.custom_radio input[type="radio"] {
	display: none;
}

.custom_radio input[type="radio"] + label {
	position: relative;
	display: inline-block;
	padding-left: 1.5em;
	margin-right: 2em;
	cursor: pointer;
	font-size: 18px;
	line-height: 1em;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.textArea textarea {
	font-family: var(--Nunito);
	width: 100%;
	font-size: 14px;
	padding: 15px;
	border-radius: 5px;
	margin-top: 15px;
	outline: 0;
	max-width: 100%;
	min-height: 100px;
	border: 1px solid #999999;
}
i {
	font-family: var(--Nunito);
}
.custom_radio input[type="radio"] + label:before,
.custom_radio input[type="radio"] + label:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 1em;
	height: 1em;
	text-align: center;
	color: white;
	border: 0.5px solid var(--DarkCyan);
	border-radius: 50%;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.custom_radio input[type="radio"] + label:before {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
	box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
}

.custom_radio input[type="radio"] + label:hover:before {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em var(--Magnolia);
	box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em var(--Magnolia);
}

.custom_radio input[type="radio"]:checked + label:before {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em var(--DarkCyan);
	box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em var(--DarkCyan);
}

body {
	background-color: var(--White);
}

.DarkLavender {
	background-color: var(--DarkLavender);
}

.DeepGreen-CyanTurquoise {
	background-color: var(--DeepGreen-CyanTurquoise);
}

.LavenderGray {
	background-color: var(--LavenderGray);
}

.DarkSlateGray {
	background-color: var(--DarkSlateGray);
}

.ChineseBlue {
	background-color: var(--ChineseBlue);
}

.ChineseSilver {
	background-color: var(--ChineseSilver);
}

.Corn {
	background-color: var(--Corn);
}

.Magnolia {
	background-color: var(--Magnolia);
}

.OldRose {
	background-color: var(--OldRose);
}

.LimeGreen {
	background-color: var(--LimeGreen);
}

.PoliceBlue {
	background-color: var(--PoliceBlue);
}

.TiffanyBlue {
	background-color: var(--TiffanyBlue);
}

.MetallicBlue {
	background-color: var(--MetallicBlue);
}

.MaximumRedPurple {
	background-color: var(--MaximumRedPurple);
}

.DarkCyan {
	background-color: var(--DarkCyan);
}

.TurkishRose {
	background-color: var(--TurkishRose);
}

.Rhythm {
	background-color: var(--Rhythm);
}

.CaribbeanGreen {
	background-color: var(--CaribbeanGreen);
}

.AmericanPurple {
	background-color: var(--AmericanPurple);
}

.TurkishRose {
	background-color: var(--TurkishRose);
}

.Gunmetal {
	background-color: var(--Gunmetal);
}

.ViridianGreen {
	background-color: var(--ViridianGreen);
}

.Rhythm {
	background-color: var(--Rhythm);
}

.CaribbeanGreen {
	background-color: var(--CaribbeanGreen);
}

.OldLavender {
	background-color: var(--OldLavender);
}

.White {
	background-color: var(--White);
	color: var(--White);
}

.WhiteC {
	color: var(--White);
}

a {
	color: unset;
	font-weight: 600;
	transition: 0.3s ease-in;
}
a:hover {
	opacity: 0.8;
}

.close_icon {
	width: 35px;
	top: 39px;
	padding: 5px;
	position: absolute;
	right: 30px;
	transition: 0.4s;
	cursor: pointer;
}

.insta_svg path {
	fill: #009ba2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--Montserat);
	color: var(--Gunmetal);
	font-weight: 500;
	line-height: 110%;
}

h1 {
	font-size: 48px;
}

h2 {
	font-weight: 500;
	font-size: 43px;
	margin-bottom: 15px;
	line-height: 52px;
}

h3 {
	font-weight: 400;
	font-size: 28px;
	line-height: 34px;
	margin-bottom: 15px;
}

h4 {
	font-weight: 500;
	font-size: 20px;
	line-height: 34px;
}

h5 {
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	margin-bottom: 20px;
}

.flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.op-5 {
	opacity: 50%;
}

.max_605 {
	max-width: 605px;
}

.max_700 {
	max-width: 700px;
}

hr {
	border: 0.5px solid rgba(44, 51, 58, 0.5);
	margin: 40px 0;
}

ul li {
	font-family: var(--Nunito);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	color: var(--Gunmetal);
	margin: 8px 0;
	letter-spacing: 0em;
	text-align: left;
}

a,
label,
::-webkit-input-placeholder {
	font-family: var(--Montserat);
}

a,
label,
:-ms-input-placeholder {
	font-family: var(--Montserat);
}

a,
label,
::-ms-input-placeholder {
	font-family: var(--Montserat);
}

a,
label,
::placeholder {
	font-family: var(--Montserat);
}

ol {
	list-style: none;
	counter-reset: item;
}

ol li {
	counter-increment: item;
	margin-bottom: 5px;
	font-family: var(--Nunito);
}

ol li:before {
	margin-right: 16px;
	margin-top: 6px;
	content: counter(item);
	background: var(--Gunmetal);
	border-radius: 100%;
	color: white;
	font-weight: 700;
	width: 25px;
	height: 25px;
	text-align: center;
	display: inline-block;
}

.display_pic {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

p,
span,
li {
	font-family: var(--Nunito);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	color: var(--Gunmetal);
	margin: 8px 0;
	letter-spacing: 0em;
	text-align: left;
}

img {
	width: 100%;
	max-width: 100%;
}

.semi-bold {
	font-weight: 500;
}

.bold {
	font-weight: 600;
}

.b_location::before {
	width: 24px;
	height: 24px;
	position: relative;
	top: 5px;
	margin-right: 8px;
}

.ct-series-a .ct-line {
	stroke: var(--Gunmetal) !important;
	stroke-width: 2px;
}

.ct-grids .ct-horizontal {
	stroke-width: 0px !important;
	stroke-dasharray: 5px;
}

.ct-series-a .ct-point {
	stroke-linecap: circle;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	stroke-width: 10px;
	stroke: var(--MaximumRedPurple) !important;
	cursor: pointer;
}

.ct-series-a .ct-point::before {
	content: "Hello";
	color: #009ba2;
}

.ct-series-a .ct-point:hover {
	stroke-width: 15px;
	stroke: var(--ChineseBlue) !important;
}

.b_phone::before {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	position: relative;
	top: 5px;
}

.b_email::before {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	position: relative;
	top: 5px;
}

.b_module::before {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	position: relative;
	top: 5px;
}

.tab {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0;
	background: transparent;
	color: var(--Gunmetal);
	border: none;
	padding: 10px 5px;
	border-radius: 0;
	margin-top: 5px !important;
	margin-right: 15px;
	-webkit-transition: 0.2s;
	transition: 0.2s;
	border-bottom: 3px solid #c0c0c0;
}

.tab:hover {
	background-color: #f4f4f4;
}

.tab.active {
	color: var(--DarkCyan);
	border-bottom: 3px solid var(--DarkCyan);
	font-weight: 500;
}

.panel {
	margin-top: 30px;
	display: none;
}

.panel.active {
	display: block;
	overflow: scroll;
}

.search_set {
	border: 1px solid rgba(44, 51, 58, 0.2);
	padding: 0 10px;
	border-radius: 5px;
	height: 45px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.search_set img {
	width: 25px;
	margin-right: 0px;
	top: 6px;
}

.search_set input {
	border: 0;
	background: transparent !important;
	outline: 0;
	font-size: 16px;
	padding: 10px;
	padding-top: 0;
}

.ml-1 {
	margin-left: 10px;
}

.ml-2 {
	margin-left: 20px;
}

.m-0 {
	margin: 0;
}

.mt-1 {
	margin-top: 10px;
}

.mt-2 {
	margin-top: 20px;
}

.mt-3 {
	margin-top: 30px;
}

.mt-4 {
	margin-top: 40px;
}

.mt-5 {
	margin-top: 50px;
}

.mt-6 {
	margin-top: 60px;
}

.mt-7 {
	margin-top: 70px;
}

.mt-8 {
	margin-top: 80px;
}

.mt-9 {
	margin-top: 90px;
}

.mt-10 {
	margin-top: 100px;
}

.mb-1 {
	margin-bottom: 10px;
}

.mb-2 {
	margin-bottom: 20px;
}

.mb-3 {
	margin-bottom: 30px;
}

.mb-4 {
	margin-bottom: 40px;
}

.mb-5 {
	margin-bottom: 50px;
}

.mb-6 {
	margin-bottom: 60px;
}

.mb-7 {
	margin-bottom: 70px;
}

.mb-8 {
	margin-bottom: 80px;
}

.mb-9 {
	margin-bottom: 90px;
}

.mb-10 {
	margin-bottom: 100px;
}

.pt-1 {
	padding-top: 10px;
}

.pt-2 {
	padding-top: 20px;
}

.pt-3 {
	padding-top: 30px;
}

.pt-4 {
	padding-top: 40px;
}

.pt-5 {
	padding-top: 50px;
}

.pt-6 {
	padding-top: 60px;
}

.pt-7 {
	padding-top: 70px;
}

.pt-8 {
	padding-top: 80px;
}

.pt-9 {
	padding-top: 90px;
}

.pt-10 {
	padding-top: 100px;
}

.pb-1 {
	padding-bottom: 10px;
}

.pb-2 {
	padding-bottom: 20px;
}

.pb-3 {
	padding-bottom: 30px;
}

.pb-4 {
	padding-bottom: 40px;
}

.pb-5 {
	padding-bottom: 50px;
}

.pb-6 {
	padding-bottom: 60px;
}

.pb-7 {
	padding-bottom: 70px;
}

.pb-8 {
	padding-bottom: 80px;
}

.pb-9 {
	padding-bottom: 90px;
}

.pb-10 {
	padding-bottom: 100px;
}

button {
	padding: 12px 35px;
	margin: 25px 0 10px 0;
	border-radius: 5px;
	border: 0;
	background-color: unset;
	font-family: var(--Montserat);
	outline: 0;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
}

button:disabled {
	opacity: 0.5;
	background-color: unset;
	color: var(--ChineseSilver);
	border: 1px solid rgba(204, 204, 204, 0.4);
}

.icon {
	width: 24px;
	height: 24px;
}

.default-btn {
	padding: 12px 35px;
	margin: 25px 0 10px 0;
	border-radius: 5px;
	border: 0;
	background-color: unset;
	font-family: var(--Montserat);
	outline: 0;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	background-color: var(--ChineseSilver);
}

.important-btn {
	padding: 12px 35px;
	margin: 25px 0 10px 0;
	border-radius: 5px;
	border: 0;
	background-color: unset;
	font-family: var(--Montserat);
	outline: 0;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	background-color: var(--DarkCyan);
	color: var(--White);
}

.outlined-btn {
	padding: 12px 35px;
	margin: 25px 0 10px 0;
	border-radius: 5px;
	border: 0;
	background-color: unset;
	font-family: var(--Montserat);
	outline: 0;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	background-color: var(--White);
	color: var(--DarkCyan);
	border: 2px solid var(--DarkCyan);
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.outlined-btn:hover {
	color: var(--White);
	background-color: var(--DarkCyan);
}

.primary-btn {
	padding: 12px 35px;
	margin: 25px 0 10px 0;
	border-radius: 5px;
	border: 0;
	background-color: unset;
	font-family: var(--Montserat);
	outline: 0;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	background-color: var(--Gunmetal);
	color: var(--White);
}

.danger-btn {
	padding: 12px 35px;
	margin: 25px 0 10px 0;
	border-radius: 5px;
	border: 0;
	background-color: unset;
	font-family: var(--Montserat);
	outline: 0;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	background-color: #ea5f5e;
	color: var(--White);
}

.tg_omori {
	opacity: 0;
	position: absolute;
}

.wrap_moon {
	margin-top: 22px;
}

.toggle_it {
	width: 25px;
}

.underline {
	border-bottom: 1px solid var(--AmericanPurple);
	color: red;
}

.custom_search {
	position: relative;
}

.custom_search .search-inner {
	position: relative;
	z-index: 20;
}

.svg_control .gratitude {
	margin-bottom: 30px;
}

.svg_control .gratitude:hover svg {
	width: 45px;
	height: 45px;
	margin-bottom: 15px;
}

.svg_control .gratitude:hover svg rect {
	fill: #f2f6ff;
}

.svg_control .gratitude:hover svg path {
	fill: #2d4bf3;
}

.svg_control .gratitude .choose_txt p {
	margin-top: 5px;
	font-size: 16px;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: left;
}

.svg_control .gratitude svg {
	width: 45px;
	height: 45px;
	margin-bottom: 15px;
}

.svg_control .gratitude svg rect {
	fill: #f2f6ff;
}

.svg_control .gratitude svg path {
	fill: #2d4bf3;
}

.svg_control .gratitude input[type="radio"] {
	opacity: 0;
	width: 0;
	height: 0;
}

.svg_control .gratitude input[type="radio"]:active ~ label {
	opacity: 1;
}

.svg_control .gratitude input[type="radio"]:checked ~ label {
	background: #f2f6ff;
	opacity: 1;
	border: 0.895745px solid #2d4bf3;
}

.svg_control .gratitude input[type="radio"]:checked ~ label svg rect {
	fill: #92b0fc;
}

.svg_control .gratitude input[type="radio"]:checked ~ label svg path {
	fill: #ffffff;
}

.nav4landing {
	background: var(--White);
	-webkit-backdrop-filter: blur(15px);
	backdrop-filter: blur(15px);
	width: 100%;
	z-index: 9999;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: fixed;
	top: 0;
	-webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
		0px 2px 8px rgba(0, 0, 0, 0.05);
	box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.nav4landing .center {
	text-align: center;
	margin: 0px auto;
	display: block;
	margin-left: auto;
	padding: 17px 0;
	margin-right: auto;
	width: 50%;
}

.nav4landing .center img {
	width: 190px;
	height: auto;
	-o-object-fit: contain;
	object-fit: contain;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.navlinks {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	width: 50%;
}

.navlinks a:active {
	color: blue;
}

.navlinks img {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	-o-object-fit: cover;
	object-fit: cover;
}

.navlinks li {
	margin: 25px 0;
	font-size: 16px;
	font-weight: 500;
	font-family: var(--Montserat);
	opacity: 0.9;
	position: relative;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.navlinks li::before {
	content: "";
	position: absolute;
	left: 0;
	width: 0%;
	height: 3px;
	background: var(--Main-Gradient);
	-webkit-transition: all 0.4s;
	transition: all 0.4s;
	bottom: 0;
}

.navlinks li:hover::before {
	opacity: 1;
	width: 100%;
}

.navlinks li:hover {
	font-weight: 700;
}

.navlinks li:active {
	border-bottom: #00838f;
}

.select_drop {
	font-family: var(--Montserat);
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.select_drop .dropdown-btn {
	padding: 10px 20px;
	margin-top: 20px;
	border-radius: 5px;
	font-weight: 500;
	border: 1px solid var(--Gunmetal);
	background-color: var(--White);
	color: var(--Gunmetal);
}

.select_drop .dropdown-content {
	position: absolute;
	-webkit-box-shadow: var(--shadow);
	box-shadow: var(--shadow);
	top: 50px;
	padding: 10px;
	background-color: var(--White);
	width: 100%;
}

.select_drop .dropdown-item {
	padding: 10px;
	cursor: pointer;
	-webkit-transform: 0.4s;
	transform: 0.4s;
}

.select_drop .dropdown-item:hover {
	background-color: var(--Magnolia);
}

.btn_only {
	--r: 5px;
	/* radius */
	--b: 2px;
	/* border width */
	background: var(--Main-Gradient);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	color: transparent;
	border-width: 1px;
	border-style: solid;
	-o-border-image: var(--Main-Gradient);
	border-image: var(--Main-Gradient);
	margin: 15px 0;
	border-radius: var(--r);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transition: 0.4s ease;
	transition: 0.4s ease;
	position: relative;
	z-index: 0;
	text-decoration: none;
	padding: 15px 30px;
	margin-right: auto;
	margin-left: auto;
}

/* check lined question for the detail of the below code */
.btn_only::before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	-webkit-transition: 0.4s ease;
	transition: 0.4s ease;
	bottom: 0;
	border-radius: var(--r);
	border-width: 1px;
	border-style: solid;
	-o-border-image: var(--Main-Gradient);
	border-image: var(--Main-Gradient);
	border-radius: var(--r);
	background: inherit;
	background-origin: border-box;
	background-clip: border-box;
	-webkit-mask: -webkit-gradient(
				linear,
				left top,
				left bottom,
				color-stop(0, #fff)
			)
			padding-box,
		-webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
	-webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: destination-out;
	mask-composite: exclude;
	-webkit-mask-repeat: no-repeat;
}

/**/
.btn_only:hover {
	color: #fff;
	-webkit-text-fill-color: #fff;
	background-clip: border-box;
}

.btn_only:hover::before {
	-webkit-mask: none;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.track {
	color: var(--DarkCyan);
	border-bottom: 3px solid var(--DarkCyan);
}

.hamburger {
	margin-top: 0px;
}

.hamburger div {
	width: 25px;
	height: 3px;
	background-color: var(--Gunmetal);
	margin: 5px;
}

ul.breadcrumb {
	list-style: none;
	margin-bottom: 40px;
}

ul.breadcrumb li {
	display: inline;
	font-size: 18px;
	margin: 0;
	font-weight: 600;
	color: var(--Gunmetal);
}

ul.breadcrumb li a {
	color: var(--ChineseSilver);
	text-decoration: none;
	-webkit-transition: 0.7s ease;
	transition: 0.7s ease;
}

ul.breadcrumb li a:hover {
	color: var(--ChineseSilver);
	text-decoration: underline;
}

#myProgress {
	margin-top: 20px;
	width: 100%;
	border-radius: 5px;
	height: 8px;
	background-color: #f4f4f4;
}

#myProgress #myBar {
	width: 10%;
	border-radius: 4px;
	height: 8px;
	background-color: var(--DarkCyan);
}

.border-top {
	border-top: 1px solid var(--ChineseSilver);
	margin-bottom: 40px;
}

.c_p {
	cursor: pointer;
}

.tab {
	float: left;
}

.tab__group {
	position: relative;
	height: 340px;
}

.tab__group:after {
	content: "";
	display: block;
	clear: both;
}

.tab__label {
	display: inline-block;
	padding: 0 20px;
	margin-right: 5px;
	font-size: 16px;
	line-height: 40px;
	font-weight: 500;
	cursor: pointer;
	color: var(--DarkCyan);
	border-bottom: 3px solid var(--DarkCyan);
}

.tab__content {
	position: absolute;
	width: 100%;
	top: 40px;
	left: 0;
	right: 0;
	background: #fff;
}

.tab__radio {
	display: none;
}

.tab__radio:not(:checked) ~ .tab__label {
	font-size: 16px;
	font-weight: 400;
	color: var(--Gunmetal);
	background: transparent;
	border-bottom: 0;
}

.tab__radio:not(:checked) ~ .tab__content {
	display: none;
}

.container {
	max-width: 1680px;
	margin: 0 auto;
	margin-bottom: 150px;
	padding: 0 20px;
}

#overlay_ {
	display: none;
}

.overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.2);
}

.overlay .it_card {
	background-color: var(--White);
	padding: 20px;
	margin: 25px;
	top: 20%;
	position: absolute;
	max-width: 550px;
	width: 90%;
}

.overlay .it_card .it_top {
	height: 70px;
	background-color: var(--ChineseSilver);
	text-align: center;
	padding-top: 20px;
}

.overlay .it_card .it_top h3 {
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.overlay .it_card .it_content {
	padding: 20px;
}

.overlay .it_card .bbtn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.overlay .it_card .bbtn button {
	width: 25%;
	margin-right: 45px;
}

.overlay .it_card .bbtn div {
	margin-top: 40px;
	font-weight: 600;
	cursor: pointer;
	font-family: var(--Montserat);
}

table {
	border-collapse: collapse;
	font-family: var(--Nunito);
	margin: 25px 0;
	font-size: 16px;
	font-weight: 600;
	width: 100%;
}

table td {
	min-width: 100px;
	padding: 10px 18px;
}

table thead tr {
	background-color: var(--LavenderGray);
	text-align: left;
	border: 1px solid rgba(44, 51, 58, 0.1);
	color: var(--Gunmetal);
	font-weight: 500;
	border-radius: 5px 5px 0px 0px;
}
table tbody tr.header {
	background-color: #dedede;
}
table tbody tr.header input {
	text-align: left;
	border: 1px solid rgba(44, 51, 58, 0.1);
	color: var(--Gunmetal);
	font-weight: 600;
	border-radius: 5px 5px 0px 0px;
}
table tbody tr input {
	border: var(--borderDefault);
	width: auto;
}

table thead tr:nth-of-type(1) {
	background-color: #f3f3f3;
}

table tbody tr {
	border: 1px solid rgba(44, 51, 58, 0.1);
	font-size: 15px;
	font-weight: 500;
}

table tbody tr:nth-of-type(even) {
	background-color: #f3f3f3;
	font-size: 15px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}

table th,
table td {
	padding: 18px;
}

.pagination {
	display: inline-block;
	width: 100%;
	margin: 30px auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}

.pagination a {
	color: black;
	float: left;
	font-weight: 600;
	padding: 8px 16px;
	margin: 0 5px;
	text-decoration: none;
}

.pagination a:hover {
	color: var(--DarkCyan);
}

.testimonial_box {
	margin-top: 70px;
	margin-bottom: 40px;
}

.testimonial_box .p_reviews {
	max-width: 410px;
}

.testimonial_box .p_reviews h4 {
	font-weight: 600;
	font-size: 32px;
	line-height: 39px;
}

.testimonial_box .t_avatars {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 24px;
}

.testimonial_box .t_avatars .avatars__image {
	-webkit-box-shadow: 0 0 0 4px #fff;
	box-shadow: 0 0 0 4px #fff;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 9999px;
}

.testimonial_box .t_avatars .avatars__image p {
	margin-top: 10px;
	font-weight: 700;
	font-size: 18px;
}

.testimonial_box .t_avatars .avatars__image img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 3px solid var(--White);
}

.testimonial_box .t_avatars .avatars__image .lap_2 {
	position: relative;
	left: -8px;
}

.testimonial_box .t_avatars .avatars__image .lap_3 {
	position: relative;
	left: -16px;
}

.testimonial_box .t_avatars .avatars__image .lap_4 {
	position: relative;
	left: -24px;
}

.testimonial_box .t_avatars .avatars__image .lap_5 {
	position: relative;
	left: -32px;
}

.testimonial_box .t_avatars .avatars__image .lap_6 {
	position: relative;
	left: -40px;
}

.testimonial_box .t_avatars .avatars__image .lap_7 {
	position: relative;
	left: -48px;
}

.uni_results {
	z-index: 10;
	display: none;
	position: absolute;
	width: 100%;
	border-radius: 5px;
	-webkit-box-shadow: var(--shadow);
	box-shadow: var(--shadow);
	background-color: var(--White);
	top: 0;
	-webkit-animation: uni_results 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: uni_results 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.uni_results ul {
	margin-bottom: 20px;
}

.uni_results ul li {
	padding: 10px;
	margin: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.uni_results ul li:hover {
	background-color: var(--Magnolia);
}

.show .uni_results {
	display: block;
}

@-webkit-keyframes uni_results {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
	}
}

@keyframes uni_results {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
	}
}

.pagination a.active {
	border-bottom: 3px solid var(--DarkCyan);
	font-weight: 600;
}

.pagination a:hover:not(.active) {
	background-color: #ddd;
	border-radius: 5px;
}

.top_tool::before {
	content: "";
	width: 24px;
	height: 24px;
	background-color: var(--ChineseSilver);
	display: inline-block;
	position: absolute;
	top: -12px;
	left: 90%;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.rc-pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
	margin-right: 8px;
}

.rc-pagination-total-text {
	margin-right: 12px;
	cursor: initial;
}

.rc-pagination-jump-next,
.rc-pagination-jump-prev,
.rc-pagination-next,
.rc-pagination-prev {
	display: inline-block;
	min-width: 28px;
	height: 28px;
	color: rgba(0, 0, 0, 0.85);
	font-family: Arial;
	line-height: 28px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	border-radius: 2px;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.rc-pagination-jump-next button,
.rc-pagination-jump-prev button {
	background: transparent;
	border: none;
	cursor: pointer;
	color: #666;
}

.rc-pagination-jump-next button:after,
.rc-pagination-jump-prev button:after {
	display: block;
	content: "...";
}

.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-total-text {
	min-width: initial;
	height: auto;
	line-height: initial;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.rc-pagination-item a,
.rc-pagination-item button,
.rc-pagination-prev a,
.rc-pagination-prev button,
.rc-pagination-next a,
.rc-pagination-next button,
.rc-pagination-total-text a,
.rc-pagination-total-text button {
	padding: 6px 8px;
	height: auto;
	min-width: 32px;
	min-height: 32px;
	border: 1px solid transparent;
	background-color: transparent;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 500;
	color: #656f84 !important;
	transition: 0.3s;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-o-transition: 0.3s;
}

.rc-pagination-item.rc-pagination-item-active a,
.rc-pagination-item.rc-pagination-item-active a:hover,
.rc-pagination-prev.rc-pagination-item-active a,
.rc-pagination-prev.rc-pagination-item-active a:hover,
.rc-pagination-next.rc-pagination-item-active a,
.rc-pagination-next.rc-pagination-item-active a:hover,
.rc-pagination-total-text.rc-pagination-item-active a,
.rc-pagination-total-text.rc-pagination-item-active a:hover {
	border-bottom: 2px solid var(--DarkCyan);
}

.rc-pagination-item a:hover,
.rc-pagination-item button:hover,
.rc-pagination-prev a:hover,
.rc-pagination-prev button:hover,
.rc-pagination-next a:hover,
.rc-pagination-next button:hover,
.rc-pagination-total-text a:hover,
.rc-pagination-total-text button:hover {
	background-color: #eceff5;
	border-color: #eceff5;
}

.accordion {
	width: 100%;
	-webkit-box-shadow: var(--shadow);
	box-shadow: var(--shadow);
	border: 1px solid var(--Magnolia);
	margin: 40px 0;
}

.accordion h3 {
	background-color: var(--Magnolia);
	margin: 0;
	padding: 20px;
	font-size: 17px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0em;
}

.accordion input {
	display: none;
}

.acc_label,
.acc_content {
	padding: 14px 20px;
}

.acc_label input,
.acc_content input {
	display: block;
}

.acc_label {
	display: block;
	color: var(--Gunmetal);
	font-weight: 500;
	cursor: pointer;
	position: relative;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
	background-color: var(--White);
	border: 1px solid var(--Magnolia);
}

.acc_label::after {
	content: "+";
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 20px;
	font-size: 20px;
	-webkit-transition: -webkit-transform 0.4s;
	transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	transition: transform 0.4s, -webkit-transform 0.4s;
}

.acc_label:hover {
	background: var(--DarkCyan);
	color: var(--White);
}

.acc_content {
	background-color: var(--DarkCyan);
	color: white;
	font-weight: 0.85em;
	line-height: 1.6;
	display: none;
}

.acc_content p {
	color: var(--White);
}

.acc_input:checked ~ .acc_label {
	background-color: var(--DarkCyan);
	color: var(--White);
}

.acc_input:checked ~ .acc_content {
	display: block;
	background-color: var(--DarkCyan);
}

.acc_input:checked ~ .acc_label::after {
	content: "-";
	-webkit-transform: translateY(-50%) rotate(0.5turn);
	transform: translateY(-50%) rotate(0.5turn);
}

.bottom_tool::before {
	content: "";
	width: 24px;
	height: 24px;
	background-color: var(--ChineseSilver);
	display: inline-block;
	position: absolute;
	bottom: -12px;
	left: 48%;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.right_tool::before {
	content: "";
	width: 24px;
	height: 24px;
	background-color: var(--ChineseSilver);
	display: inline-block;
	position: absolute;
	right: -12px;
	top: 48%;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.tools_tip {
	background-color: var(--ChineseSilver);
	padding: 16px;
	border-radius: 5px;
	margin: 30px 0;
	position: relative;
}

.tools_tip p {
	margin: 0;
}

pre {
	background-color: #f3f3f3;
	font-size: 18px;
	padding: 20px;
	max-height: 450px;
	overflow: scroll;
}

.input_parent {
	margin: 25px 0;
	height: 50px;
	font-style: var(--Montserat);
	position: relative;
	background-color: transparent;
}

.input_parent label {
	position: absolute;
	left: 15px;
	z-index: -2;
	top: 15px;
	-webkit-transition: top 0.4s;
	transition: top 0.4s;
}

input {
	width: 100%;
	font-style: var(--Montserat);
	padding: 15px;
	border: 1px solid rgba(44, 51, 58, 0.5);
	border-radius: 5px;
	border-radius: 5px;
	height: 50px;
	font-size: 15px;
	outline: 0;
	background-color: var(--White);
}

.radio {
	display: block;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-align: left;
}

.radio p {
	opacity: 50%;
	margin-top: 0;
	margin-left: 40px;
}

.radio input {
	display: none;
}

.radio input:disabled {
	display: none;
}

.radio input + span {
	display: inline-block;
	position: relative;
	padding-left: 40px;
	font-weight: 500;
	font-size: 16px;
	margin-top: 10px;
}

.radio input + span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	margin-right: 5px;
	width: 20px;
	height: 20px;
	border: 1px solid #00838f;
}

.radio input + span::after {
	content: "";
	display: block;
	width: 10px;
	height: 10px;
	background-color: #00838f;
	position: absolute;
	border-radius: 50%;
	position: absolute;
	top: 6px;
	left: 6px;
	-webkit-transform: scale(0, 0);
	transform: scale(0, 0);
	opacity: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
	transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.radio input + h3 {
	display: block;
	position: relative;
	padding-top: 23px;
	margin: 10px;
	font-size: 18px;
	font-weight: 600;
	padding-left: 5px;
}

.radio input + h3::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	margin-right: 5px;
	width: 20px;
	height: 20px;
	border: 1px solid #00838f;
}

.radio input + h3::after {
	content: "";
	display: block;
	width: 10px;
	height: 10px;
	background-color: #00838f;
	position: absolute;
	border-radius: 50%;
	position: absolute;
	top: 6px;
	left: 6px;
	-webkit-transform: scale(0, 0);
	transform: scale(0, 0);
	opacity: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
	transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.radio input:checked + span::after,
.radio input:checked + h3::after {
	opacity: 1;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}

.radio_disabled {
	display: block;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-align: left;
}

.radio_disabled input:disabled {
	display: none;
}

.radio_disabled input:disabled + span {
	display: inline-block;
	position: relative;
	padding-left: 30px;
	margin-top: 10px;
}

.radio_disabled input:disabled + span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	margin-right: 5px;
	width: 20px;
	height: 20px;
	background-color: #f4f3fe;
}

.radio_disabled input:disabled + span::after {
	content: "";
	display: block;
	width: 10px;
	height: 10px;
	background-color: #c5c0db;
	position: absolute;
	border-radius: 50%;
	position: absolute;
	top: 6px;
	left: 6px;
	-webkit-transform: scale(0, 0);
	transform: scale(0, 0);
	-webkit-transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
	transition: all 0.3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.check_parent .checkbox {
	display: block;
	font-family: var(--Nunito);
	position: relative;
	padding-left: 40px;
	padding-top: 10px;
	-webkit-transition: 0.2s;
	transition: 0.2s;
	font-size: 16px;
	font-weight: 400;
	-webkit-user-select: none;
	border-radius: 5px;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.check_parent .checkbox p {
	opacity: 50%;
}

.check_parent .checkbox input {
	position: absolute;
	width: auto;
	-webkit-transition: 0.2s ease;
	transition: 0.2s ease;
	opacity: 0;
	border-radius: 5px;
	cursor: pointer;
}

.check_parent .checkbox input:checked ~ .checkmark {
	background: var(--Main-Gradient);
	border: 0;
}

.check_parent .checkbox input:checked ~ .checkmark:after {
	display: block;
}

.check_parent .checkbox input:disabled ~ .checkmark {
	background-color: var(--White);
}

.check_parent .checkbox .checkmark {
	position: absolute;
	top: 0;
	cursor: pointer;
	left: 0;
	border-radius: 5px;
	height: 25px;
	-webkit-transition: 0.2s ease;
	transition: 0.2s ease;
	width: 25px;
	background-color: var(--White);
	border: 1px solid var(--Gunmetal);
}

.check_parent .checkbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
	left: 9px;
	top: 4px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

input:focus ~ label {
	top: -15px;
	color: #00838f;
	background-color: white;
	padding: 5px;
	font-size: 14px;
	z-index: 2;
}

input:focus {
	background-color: transparent;
	z-index: 2;
	height: 50px;
	border-radius: 5px;
}

input:focus::-webkit-input-placeholder {
	color: transparent;
}

input:focus:-ms-input-placeholder {
	color: transparent;
}

input:focus::-ms-input-placeholder {
	color: transparent;
}

input:focus::placeholder {
	color: transparent;
}

input:valid {
	background-color: #ffffff;
}

.custom-select {
	position: relative;
	font-family: Arial;
	border: 1px solid var(--Gunmetal);
	border-radius: 5px;
	min-width: 130px;
}

.custom-select select {
	display: none;
	/*hide original SELECT element:*/
}

.select-selected {
	background-color: transparent;
	border: 1px solid rgba(44, 51, 58, 0.7);
	border-radius: 5px;
	font-family: var(--Montserat);
}

/*style the arrow inside the select element:*/
.select-selected:after {
	position: absolute;
	content: "";
	top: 14px;
	right: 10px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-color: var(--Gunmetal) transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
	border-color: transparent transparent var(--Gunmetal) transparent;
	top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
	color: var(--Gunmetal);
	padding: 8px 16px;
	border: 1px solid var(--Gunmetal);
	border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/*style items (options):*/
.select-items {
	position: absolute;
	background-color: var(--Magnolia);
	top: 100%;
	left: 0;
	right: 0;
	-webkit-transition: 0.9s;
	transition: 0.9s;
	z-index: 99;
}

.main_drop {
	max-width: 120px;
	font-family: var(--Montserat);
	width: 100%;
	position: relative;
}

.main_drop .main_drop_btn {
	padding: 10px 12px;
	background-color: var(--White);
	border: 1px solid var(--Magnolia);
	border-radius: 5px;
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.main_drop .main_drop_btn img {
	width: 20px;
	height: 20px;
}

.main_drop .selections {
	border-radius: 6px;
	background-color: #fff;
	position: absolute;
	top: 52px;
	border: 1px solid var(--Magnolia);
	width: 100%;
}

.main_drop .selections .selected {
	padding: 10px 12px;
	cursor: pointer;
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
}

.main_drop .selections .selected:hover {
	background-color: var(--Magnolia);
}

/*hide the items when the select box is closed:*/
.select-hide {
	display: none;
}

.select-items div:hover,
.same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}

.search_parent {
	position: relative;
}

.search_parent input {
	padding: 15px 20px 15px 48px;
}

.search_parent img {
	width: 20px;
	height: 24px;
	position: absolute;
	top: 15px;
	left: 16px;
}

.password_parent {
	border-radius: 5px;
	height: 50px;
	width: 100%;
	position: relative;
	background-color: transparent;
	margin: 20px 0 10px 0;
}

.password_parent input {
	width: 100%;
	padding: 15px;
	border: 0;
	outline: 0;
	border: 1px solid var(--ChineseSilver);
	background-color: transparent;
}

.password_parent input:focus ~ label {
	top: -12px;
	color: var(--DarkCyan);
	z-index: 2;
}

.password_parent input:focus {
	background-color: var(--ChineseSilver);
	border: 1px solid var(--DarkCyan);
	z-index: 2;
}

.password_parent input:focus::-webkit-input-placeholder {
	color: transparent;
}

.password_parent input:focus:-ms-input-placeholder {
	color: transparent;
}

.password_parent input:focus::-ms-input-placeholder {
	color: transparent;
}

.password_parent input:focus::placeholder {
	color: transparent;
}

.password_parent input:valid {
	background-color: var(--White);
}

.password_parent span {
	margin: 5px 0;
	color: red;
	opacity: 0;
}

.password_parent .tribute {
	position: absolute;
	right: 30px;
	top: 13px;
}

.password_parent.active input {
	border: 1px solid red;
}

.password_parent.active input:focus ~ label {
	top: -12px;
	color: red;
	z-index: 2;
}

.password_parent.active span {
	opacity: 1;
}

.breadcrumb_control .breadcrumb {
	background: none;
	padding: 0;
}

.breadcrumb_control .breadcrumb .current_bread {
	margin-left: 0px;
}

.password_parent label {
	position: absolute;
	left: 15px;
	z-index: -2;
	top: 15px;
	-webkit-transition: top 0.4s;
	transition: top 0.4s;
}

#hide {
	display: none;
}

.form_x h3 {
	margin-bottom: 30px;
}

.form_x h5 {
	font-size: 20px;
	font-weight: 400;
	line-height: 27px;
	letter-spacing: 0em;
	margin: 20px 0 0 0;
	text-align: left;
}

.tool img {
	width: 20px;
	height: 20px;
}

.form-overlay {
	background: var(--White);
	margin-top: 100px;
	margin-bottom: 100px;
	padding: 24px;
}

.form-overlay h3 {
	margin-top: 15px;
	font-weight: 600;
	font-size: 25px;
	line-height: 40px;
}

.form-overlay h5 {
	margin-bottom: 0;
}

.form-overlay .both_disk {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	max-width: 200px;
	margin-bottom: 15px;
}

.form-overlay .both_disk span {
	margin: 0;
}

.form-overlay .take_actions {
	margin-top: 30px;
	margin-bottom: 50px;
}

.form-overlay .take_actions p {
	text-align: center !important;
	width: 100%;
	padding: 10px 15px;
	border: 1px solid var(--DarkCyan);
}

.form-overlay .take_actions button {
	padding: 15px;
	margin-top: 10px;
	width: 100%;
	color: var(--White);
}

.form-overlay textarea {
	width: 100%;
	max-width: 100%;
	font-size: 16px;
	color: #555;
	margin-bottom: 25px;
	outline: none;
	border: 1px solid #bbb;
	padding: 15px;
	min-height: 200px;
	height: 100%;
	border-radius: 5px;
	position: relative;
}

.anchor_resume {
	border: 1px solid var(--Gunmetal);
	padding: 10px 20px;
	border-radius: 5px;
}

.anchor_resume:before {
	content: url("../assets/svg/pdf.svg");
	content: "";
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 5px;
	background: greenyellow;
	display: inline-block;
}

.date_of {
	min-width: 170px !important;
}

.date_of button {
	margin: 0;
	width: auto;
	padding: 8px 12px;
}

.date_of button[name="invite"] {
	margin-right: 10px;
	background: var(--DarkCyan);
	border: 1px solid var(--DarkCyan);
	color: white;
}

.date_of button[name="reject"] {
	background: transparent;
	border: 1px solid red;
	color: red;
}

.label-cbx {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	margin-bottom: 0;
}

.label-cbx input:checked + .checkbox {
	border-color: var(--DarkCyan);
}

.label-cbx input:checked + .checkbox svg path {
	fill: var(--DarkCyan);
}

.label-cbx input:checked + .checkbox svg polyline {
	stroke-dashoffset: 0;
}

.label-cbx:hover .checkbox svg path {
	stroke-dashoffset: 0;
}

.label-cbx .checkbox {
	position: relative;
	top: 2px;
	float: left;
	margin-right: 8px;
	width: 20px;
	height: 20px;
	border: 2px solid #c8ccd4;
	border-radius: 3px;
}

.label-cbx .checkbox svg {
	position: absolute;
	top: -2px;
	left: -2px;
}

.label-cbx .checkbox svg path {
	fill: none;
	stroke: var(--DarkCyan);
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-dasharray: 71px;
	stroke-dashoffset: 71px;
	-webkit-transition: all 0.6s ease;
	transition: all 0.6s ease;
}

.label-cbx .checkbox svg polyline {
	fill: none;
	stroke: #fff;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-dasharray: 18px;
	stroke-dashoffset: 18px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.label-cbx > span {
	pointer-events: none;
	vertical-align: middle;
}

.switch_parent .switch {
	position: relative;
	display: inline-block;
	width: 48px;
	margin-top: 20px;
	height: 24px;
}

.switch_parent .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.switch_parent .switch input:checked + .slider {
	background: var(--Main-Gradient);
}

.switch_parent .switch input:focus + .slider {
	-webkit-box-shadow: 0 0 1px var(--Main-Gradient);
	box-shadow: 0 0 1px var(--Main-Gradient);
}

.switch_parent .switch input:checked + .slider:before {
	-webkit-transform: translateX(24px);
	transform: translateX(24px);
}

.switch_parent .switch input:disabled + .slider:before {
	-webkit-transform: translateX(24px);
	transform: translateX(24px);
	opacity: 50%;
}

.switch_parent .switch .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 48px;
	height: 24px;
	border-radius: 50px;
	background: rgba(204, 204, 204, 0.4);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.switch_parent .switch .slider:before {
	position: absolute;
	content: "";
	width: 16px;
	height: 16px;
	border-radius: 50%;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.switch_parent .switch .slider p {
	margin: 0 0 0 60px;
	font-weight: 400;
}

.select_me {
	width: 200px;
	position: relative;
	font-family: var(--Nunito);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-right: 10px;
}

.select_me .select-btn {
	padding: 10px;
	background: var(--White);
	border-radius: 5px;
	cursor: pointer;
	border: 1px solid rgba(44, 51, 58, 0.2);
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: var(--Gunmetal);
}

.select_me .select-btn img {
	width: 24px;
	height: 24px;
}

.select_me .select_content {
	position: absolute;
	top: 55px;
	padding: 10px;
	width: 100%;
	left: 0;
	font-weight: 500;
	background: #ffffff;
	border: 1px solid #c0c0c0;
	border-radius: 5px;
}

.select_me .select_items {
	font-size: 16px;
	font-weight: 400;
	cursor: pointer;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
	padding: 10px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-bottom: 1px solid #f5f5f5;
}

.select_me .select_items:hover {
	background-color: rgba(0, 131, 143, 0.07);
}

/*== start of code for tooltips ==*/
.tool {
	cursor: help;
	position: relative;
}

.modalContainer {
	max-width: 800px;
	margin: 30px auto;
	position: relative;
	-webkit-box-shadow: var(
		--ds-shadow-overlay,
		0 0 0 1px rgba(9, 30, 66, 0.08),
		0 2px 1px rgba(9, 30, 66, 0.08),
		0 0 20px -6px rgba(9, 30, 66, 0.31)
	);
	box-shadow: var(
		--ds-shadow-overlay,
		0 0 0 1px rgba(9, 30, 66, 0.08),
		0 2px 1px rgba(9, 30, 66, 0.08),
		0 0 20px -6px rgba(9, 30, 66, 0.31)
	);
}

.modalContainer img {
	width: 35px;
	top: 39px;
	padding: 5px;
	position: absolute;
	right: 30px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	cursor: pointer;
}

.modalContainer img:hover {
	background: var(--Magnolia);
}

.bills_on_me {
	position: fixed;
	top: 70px;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 12;
	overflow: scroll;
	padding: 0 20px;
	background: rgba(0, 0, 0, 0.3);
}

.bills_on_me .control_btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.bills_on_me .control_btn .outlined-btn {
	border: 1px solid var(--DarkCyan);
}

/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
	left: 50%;
	opacity: 0;
	position: absolute;
	z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
	opacity: 1;
	-webkit-transform: scale(1) translateY(0);
	transform: scale(1) translateY(0);
	z-index: 100;
}

/*== pointer tip ==*/
.tool::before {
	border-style: solid;
	border-width: 1em 0.75em 0 0.75em;
	border-color: #3e474f transparent transparent transparent;
	bottom: 100%;
	content: "";
	margin-left: -0.5em;
	-webkit-transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26),
		opacity 0.65s 0.5s;
	transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26),
		opacity 0.65s 0.5s;
	-webkit-transform: scale(0.6) translateY(-90%);
	transform: scale(0.6) translateY(-90%);
}

.tool:hover::before,
.tool:focus::before {
	-webkit-transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
	transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
}

/*== speech bubble ==*/
.tool::after {
	background: #3e474f;
	border-radius: 0.25em;
	bottom: 55px;
	color: #edeff0;
	content: attr(data-tip);
	margin-left: -8.75em;
	padding: 1em;
	-webkit-transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
	transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
	-webkit-transform: scale(0.6) translateY(50%);
	transform: scale(0.6) translateY(50%);
	width: 17.5em;
}

.tool:hover::after,
.tool:focus::after {
	-webkit-transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
	transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
}

@media (max-width: 760px) {
	.tool::after {
		font-size: 0.75em;
		margin-left: -5em;
		width: 10em;
	}
}

@media (max-width: 768px) {
	.navlinks {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		min-width: 280px;
		position: fixed;
		background: var(--White);
		padding: 20px;
		min-height: 300px;
		top: 0;
		z-index: 90;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		-webkit-transform: translateX(-768px);
		transform: translateX(-768px);
		left: 0;
	}
	.navlinks li {
		padding: 10px;
		margin: 0;
		border-bottom: 2px solid #f3f3f3;
	}
	.backgroundMask {
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.2);
		display: none;
	}
	.placeblur {
		display: block;
	}
	.menudrop {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
	#from_mobile {
		display: none;
	}
}

.ghost {
	display: none;
}

@media (min-width: 767px) {
	.container {
		padding: 0 40px;
	}
	.navlinks {
		margin-right: 55px;
	}
	.wrap_moon {
		position: absolute;
		top: 5px;
		cursor: pointer;
		top: 5px;
		right: 45px;
	}
	.on_nav {
		display: none;
	}
	.hamburger {
		display: none;
	}
}

@media (min-width: 1024px) {
	.container {
		padding: 0 40px;
	}
}

.swing-in-bottom-fwd {
	-webkit-animation: swing-in-bottom-fwd 0.5s
		cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
	animation: swing-in-bottom-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
		both;
}

.fade-in-fwd {
	-webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.clock::before {
	content: url("../assets/svg/clock_b.svg");
	display: inline-block;
	position: relative;
	top: 5px;
	margin-right: 7px;
}

.avatar:before {
	content: url("../assets/svg/avatar.svg");
	display: inline-block;
	position: relative;
	top: 5px;
	margin-right: 7px;
}

/**
 * ----------------------------------------
 * animation swing-in-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-bottom-fwd {
	0% {
		-webkit-transform: rotateX(100deg);
		transform: rotateX(100deg);
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		opacity: 1;
	}
}

@keyframes swing-in-bottom-fwd {
	0% {
		-webkit-transform: rotateX(100deg);
		transform: rotateX(100deg);
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		opacity: 1;
	}
}

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-fwd {
	0% {
		-webkit-transform: translateZ(-80px);
		transform: translateZ(-80px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}
}

@keyframes fade-in-fwd {
	0% {
		-webkit-transform: translateZ(-80px);
		transform: translateZ(-80px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}
}

:root {
	--DarkCyan: #00838f;
	--TurkishRose: #bc658d;
	--Gunmetal: #2c333a;
	--ViridianGreen: #009ba2;
	--White: #ffffff;
	--Rhythm: #69779b;
	--Danger: #ea5f5e;
	--FadeGlass: #ffffffaf;
	--CaribbeanGreen: #00c5a2;
	--OldLavender: #756c83;
	--AmericanPurple: #432160;
	--ChineseBlue: #415b90;
	--DarkSlateGray: #314a52;
	--MaximumRedPurple: #a03c78;
	--DarkLavender: #6f539b;
	--MetallicBlue: #3c5186;
	--TiffanyBlue: #0fabbc;
	--PoliceBlue: #394a6d;
	--Corn: #fbeb5b;
	--LimeGreen: #4be133;
	--OldRose: #394a6d;
	--Magnolia: #f4f3fe;
	--ChineseSilver: #cccccc;
	--LavenderGray: #c5c0db;
	--Main-Gradient: linear-gradient(79.85deg, #009ba2 -20.87%, #bc658d 87.74%);
	--blue: rgb(26, 57, 87);
	--white: rgb(236, 236, 236);
	--DeepGreen-CyanTurquoise: #206d62;
	--Nunito: "Nunito", sans-serif;
	--Montserat: "Montserrat", sans-serif;
	--shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
	--overlay: rgba(44, 51, 58, 0.2);
}

header {
	background: var(--White);
	-webkit-backdrop-filter: blur(15px);
	backdrop-filter: blur(15px);
	width: 100%;
	z-index: 999;
	height: auto;
	position: fixed;
	top: 0;
	-webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
		0px 2px 8px rgba(0, 0, 0, 0.05);
	box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
}

header .container {
	margin-bottom: 0;
}

header .top_nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

header .top_nav .gear_avatar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 18%;
}

header .top_nav .gear_avatar .settings_gear {
	width: 30px;
	height: 30px;
	cursor: pointer;
	margin-top: 8px;
}

header .top_nav .gear_avatar .main_drop {
	width: 120px;
}

header .top_nav .gear_avatar .main_drop .main_drop_btn {
	border: 0;
	border-radius: 0;
	border-right: 2px solid var(--Magnolia);
	cursor: pointer !important;
}

header .top_nav .gear_avatar .main_drop .select_content {
	padding: 10px 0;
}

header #topNav {
	padding: 15px 0;
}

header #topNav .logo img {
	margin-top: 10px;
	width: 177px;
	height: auto;
	-o-object-fit: cover;
	object-fit: cover;
}

.nav4landing {
	background: var(--White);
	-webkit-backdrop-filter: blur(15px);
	backdrop-filter: blur(15px);
	width: 100%;
	z-index: 9999;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	-webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
		0px 2px 8px rgba(0, 0, 0, 0.05);
	box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
}

.nav4landing .center {
	text-align: center;
	margin: 0px auto;
	display: block;
	margin-left: auto;
	padding: 17px 0;
	margin-right: auto;
	width: 50%;
}

.nav4landing .center img {
	width: 190px;
	height: auto;
	-o-object-fit: cover;
	object-fit: cover;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

nav {
	height: auto;
	padding: 10px 0;
	background: var(--DarkCyan);
	position: fixed;
	top: 70px;
	width: 100%;
	z-index: 99;
	backdrop-filter: blur(5px);
}

nav ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 48%;
	margin: 0 auto;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

nav ul .active {
	border-bottom: 2px solid var(--White);
}

nav ul li {
	font-size: 16px;
	display: table-cell;
	position: relative;
	padding: 3px;
	font-weight: 600;
	line-height: 22px;
	color: var(--White);
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	text-align: left;
	letter-spacing: 1px;
}

nav ul li:after {
	background: none repeat scroll 0 0 transparent;
	bottom: 0;
	content: "";
	display: block;
	height: 2px;
	left: 50%;
	position: absolute;
	background: rgba(0, 78, 96, 0.337);
	-webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
	transition: width 0.3s ease 0s, left 0.3s ease 0s;
	width: 0;
}

nav ul li:hover {
	letter-spacing: 0.5px;
}

nav ul li:hover:after {
	width: 100%;
	left: 0;
}

@media (min-width: 980px) {
	header .top_nav .gear_avatar {
		width: 300px;
	}
}

@media (max-width: 980px) {
	header .top_nav .gear_avatar {
		width: 25%;
	}
	nav ul {
		width: 74%;
	}
}

@media (max-width: 768px) {
	nav ul {
		width: 85%;
	}
	header .top_nav .gear_avatar {
		width: 30%;
	}
}

@media (max-width: 700px) {
	nav ul {
		width: 100%;
		text-align: left;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	nav ul li {
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		padding: 14px 20px;
		margin: 0;
		border-bottom: 0.1px solid var(--White);
	}
	header .top_nav .gear_avatar {
		width: 120px;
		position: absolute;
		right: 60px;
	}
	header .top_nav .gear_avatar .main_drop {
		display: none;
	}
	header .top_nav .gear_avatar .settings_gear {
		display: none;
	}
	header .top_nav {
		position: relative;
	}
	nav {
		padding: 0;
	}
	.menu_links {
		-webkit-transform: translateY(-382px);
		transform: translateY(-382px);
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
	.menu_links.active {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	.hamburger {
		margin-top: 5px;
	}
	.hamburger .line {
		width: 25px;
		height: 3px;
		background-color: var(--Gunmetal);
		display: block;
		margin: 5px auto;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
	#hamburger-1.is-active .line:nth-child(2) {
		opacity: 0;
	}
	#hamburger-1.is-active .line:nth-child(1) {
		-webkit-transform: translateY(13px) rotate(45deg);
		transform: translateY(13px) rotate(45deg);
	}
	#hamburger-1.is-active .line:nth-child(3) {
		-webkit-transform: translateY(-3px) rotate(-45deg);
		transform: translateY(-3px) rotate(-45deg);
	}
}

:root {
	--DarkCyan: #00838f;
	--TurkishRose: #bc658d;
	--Gunmetal: #2c333a;
	--ViridianGreen: #009ba2;
	--White: #ffffff;
	--Rhythm: #69779b;
	--CaribbeanGreen: #00c5a2;
	--OldLavender: #756c83;
	--AmericanPurple: #432160;
	--ChineseBlue: #415b90;
	--DarkSlateGray: #314a52;
	--MaximumRedPurple: #a03c78;
	--DarkLavender: #6f539b;
	--MetallicBlue: #3c5186;
	--TiffanyBlue: #0fabbc;
	--PoliceBlue: #394a6d;
	--Corn: #fbeb5b;
	--LimeGreen: #4be133;
	--OldRose: #394a6d;
	--Magnolia: #f4f3fe;
	--ChineseSilver: #cccccc;
	--LavenderGray: #c5c0db;
	--Main-Gradient: linear-gradient(79.85deg, #009ba2 -20.87%, #bc658d 87.74%);
	--blue: rgb(26, 57, 87);
	--white: rgb(236, 236, 236);
	--DeepGreen-CyanTurquoise: #206d62;
	--Nunito: "Nunito", sans-serif;
	--Montserat: "Montserrat", sans-serif;
	--shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
	--overlay: rgba(44, 51, 58, 0.2);
}

.gear_avatar img {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	border: 2px solid #fff;
}

.control_top {
	margin-top: 180px;
	margin-bottom: 40px;
}

.fourcard {
	margin-top: 50px;
}

.fourcard .grid {
	margin-bottom: 25px;
}

.fourcard .show_card {
	border: var(--borderDefault);
	border-radius: 5px;
	padding: 16px;
}

.fourcard .show_card .verifiedemail::after {
	content: url("../assets/svg/verified_yes.svg");
	display: inline-block;
	width: 30px;
	height: 30px;
	position: relative;
	top: 5px;
	margin-left: 7px;
}

.fourcard .show_card .notverifiedemail::after {
	content: url("../assets/svg/verified_no.svg");
	display: inline-block;
	width: 30px;
	height: 30px;
	position: relative;
	top: 5px;
	margin-left: 7px;
}

.fourcard .show_card .card_title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 25px;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.fourcard .show_card .card_title h3 {
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 0;
}

.fourcard .show_card .card_title p {
	cursor: pointer;
	color: var(--DarkCyan);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.fourcard .show_card .card_title p:hover {
	text-decoration: underline;
}

.fourcard .show_card .date_f {
	margin-top: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.fourcard .show_card .date_f button {
	padding: 8px 15px;
	margin-top: 0;
	color: var(--White);
}

.fourcard .show_card .date_f .actioned {
	color: var(--DarkCyan);
}

.fourcard .show_card .date_f .not_actioned {
	color: #ea5f5e;
}

.fourcard .show_card .date_f p {
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}

.fourcard .show_card .posters_name {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 15px;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.fourcard .show_card .posters_name h4 {
	font-weight: 400;
	font-size: 16px;
	line-height: 27px;
	text-decoration: underline;
}

.fourcard .show_card .posters_name img {
	width: 30px;
	height: 30px;
}

.fourcard .show_card .source_status {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: 250px;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 10px;
}

.fourcard .show_card .source_status .eligible {
	background: rgba(45, 164, 78, 0.04);
	border: 1px solid #2da44e;
	border-radius: 5px;
	padding: 2px 10px;
	margin-top: 10px;
}

.fourcard .show_card .source_status .in-eligible {
	background: #ea5f5e;
	border: 1px solid #2da44e;
}

.fourcard .show_card .source_status h4 {
	margin-bottom: 4px;
	font-size: 16px;
	font-weight: 700;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;
}

.fourcard .show_card .source_status p {
	margin: 0;
	text-transform: capitalize !important;
	display: inline-block;
}

.avatarTest .enrolMode {
	position: relative;
}

.mini_contents {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.mini_contents button {
	margin: 0;
	width: 50%;
	font-size: 16px;
	font-weight: 500;
	width: 200px;
	margin-right: 10px;
	color: var(--DarkCyan);
	padding-left: 40px;
	padding-right: 20px;
	border: 1px solid #00838f;
	border-radius: 5px;
	position: relative;
}

.mini_contents button:before {
	content: url("../assets/svg/email.svg");
	position: absolute;
	left: 15px;
	top: 10px;
}

.mini_contents .file button {
	background-color: var(--DarkCyan);
	color: var(--White);
}

.mini_contents .file button:before {
	content: url("../assets/svg/upload.svg");
	position: absolute;
	left: 20px;
	top: 10px;
}

.mini_contents h6 {
	opacity: 0;
}

.mini_contents .email_analysis {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.mini_contents .email_analysis button {
	background: rgba(0, 131, 143, 0.1);
}

.mini_contents .email_analysis .select_me {
	width: 50%;
	width: 160px;
	margin-right: 10px;
}

.mini_contents .email_analysis .select_me .select-btn {
	background: var(--DarkCyan);
	color: var(--White);
	height: 45px;
	text-align: center;
}

.mini_contents .email_analysis .select_me .select-btn::after {
	content: url("../assets/svg/white_chevron.svg");
	width: 30px;
	margin-top: 6px;
	height: 30px;
}

.input_drop {
	padding: 0 !important;
	border: 0 !important;
	height: auto !important;
}

.analysis_data {
	border: 1px solid rgba(44, 51, 58, 0.2);
	border-radius: 5px;
	padding: 18px;
	margin-top: 40px;
}

.analysis_data #border {
	border: 0;
}

.analysis_data .contents {
	border-bottom: 1.5px solid rgba(44, 51, 58, 0.2);
	padding: 12px 0;
}

.analysis_data .contents img {
	width: 35px;
	height: 35px;
}

.analysis_data .contents .data_num {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-top: 40px;
}

.analysis_data .contents .data_num h2 {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	line-height: 15px;
	margin: 0;
	margin-top: 5px;
}

.analysis_data .contents .data_num p {
	font-size: 18px;
	font-weight: 700;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: right;
}

.lead-tt {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: 40px;
}

.lead-tt h3 {
	margin-bottom: 10px;
	font-size: 20px;
	margin-top: 20px;
	font-weight: 600;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
}

.lead-tt .fiterCase {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.lead-tt .fiterCase .search_input {
	width: 300px;
}

.files_boot h5 {
	margin: 0;
}

.files_boot p {
	font-size: 14px !important;
	line-height: 20px;
}

.all_overview {
	margin-top: 30px;
	margin-bottom: 20px;
}

.all_overview .per_overview {
	background-color: yellow;
	border: 2px solid #e1e1e1;
	border-radius: 5px;
	background-color: var(--White);
	padding: 15px;
	margin-bottom: 20px;
}

.all_overview .per_overview > div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-top: 25px;
}

.all_overview .per_overview h3 {
	font-size: 22px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	margin: 0;
}

.all_overview .per_overview p {
	font-size: 20px;
	font-weight: 400;
	line-height: 22px;
	margin: 0;
	letter-spacing: 0em;
}

.all_overview .per_overview img {
	width: 40px;
	height: 40px;
}

.checkmarking {
	position: relative;
	width: 20px !important;
}

.checkmarking input {
	opacity: 0;
	position: absolute;
}

.analysis {
	margin-top: 30px;
}

.analysis h1 {
	font-weight: 700;
	font-size: 23px;
	line-height: 22px;
	margin-bottom: 30px;
}

.analysis .absolute_analysis {
	position: relative;
	margin-bottom: 20px;
	border-bottom: 3px dotted rgba(44, 51, 58, 0.2);
}

.analysis .per_als {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	width: 100%;
	top: 5px;
	z-index: 10;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.analysis .per_als h2 {
	font-weight: 400;
	font-size: 16px;
	line-height: 27px;
	margin: 0;
	background-color: var(--White);
	padding: 0 5px;
}

.analysis .per_als p {
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
	margin: 0;
	padding: 0 5px;
	background-color: var(--White);
}

.adding_new {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.adding_new button {
	margin-top: 0;
}

.apparent {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.apparent button {
	background: #009ba2;
	-webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
		0px 2px 8px rgba(0, 0, 0, 0.05);
	box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
}

.custom_uploader {
	position: relative;
	height: 200px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border: 1px solid var(--LavenderGray);
	margin-bottom: 40px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.custom_uploader .tag_upload {
	margin-bottom: 30px;
	position: relative;
}

.custom_uploader label {
	display: block;
	position: static;
	width: 200px;
	background: var(--DarkCyan);
	height: 50px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	text-align: center;
	color: var(--White);
	border-radius: 5px;
	font-weight: 500;
	font-size: 16px;
	line-height: 50px;
}

.custom_uploader .cloud {
	text-align: center;
	position: static;
	width: 50px;
	height: 50px;
}

.custom_uploader input {
	opacity: 0;
	position: absolute;
	left: 0;
}

.actual_content {
	padding-left: 65px;
	max-width: 200px;
	position: relative;
}

.actual_content:before {
	content: url("../assets/svg/files.svg");
	position: absolute;
	left: 20px;
	top: 30px;
}

.actual_content h5 {
	margin-bottom: 0;
}

.actual_content p {
	margin: 0;
}

.leadboard_btn {
	min-width: 230px !important;
}

.leadboard_btn button {
	padding: 8px 12px;
	margin: 0;
}

.resume_data {
	min-width: 160px !important;
}

.action_table {
	min-width: 230px !important;
}

.each_card {
	-webkit-box-shadow: var(--shadow);
	box-shadow: var(--shadow);
	position: relative;
	background: #ffffff;
	-webkit-box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05),
		0px 2px 8px rgba(0, 0, 0, 0.05);
	box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
}

.each_card .price {
	position: absolute;
	top: 10px;
	left: 20px;
	padding: 4px 14px;
	background: var(--PoliceBlue);
	color: var(--White);
	border-radius: 10px;
	margin: 0;
}

.each_card img {
	-o-object-fit: cover;
	object-fit: cover;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.each_card .event_details {
	padding: 20px 15px;
}

.each_card .event_details h4 {
	font-size: 16px;
}

.each_card .event_details .heading_details {
	width: 78%;
}

.each_card .event_details .heading_details p {
	height: 90px;
	overflow: hidden;
}

.each_card .heading_details h4 {
	font-weight: 400;
	font-size: 16px;
	line-height: 27px;
	width: auto;
}

.each_card .heading_details h3 {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 5px;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}

.scrollable_table {
	overflow-x: auto;
	overflow-y: hidden;
}

@media (max-width: 540px) {
	.lead-tt {
		display: block;
	}
	.lead-tt h3 {
		margin-bottom: 30px;
	}
	.lead-tt .fiterCase {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		width: 100%;
	}
	.lead-tt .fiterCase .search_input {
		width: 100%;
	}
	.apparent {
		margin-top: 50px;
	}
	.apparent button {
		width: 100%;
	}
	.mini_contents,
	.mini_contents .email_analysis {
		display: block;
	}
	.mini_contents button,
	.mini_contents .email_analysis button {
		margin-right: 0;
		background: rgba(0, 131, 143, 0.1);
	}
	.adding_new button {
		margin-top: 30px;
	}
	.search_set {
		margin-top: 20px;
	}
}

@media (max-width: 441px) {
	.mini_contents .contents {
		display: none;
	}
	.mini_contents .email_analysis {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 auto;
	}
	.mini_contents .email_analysis button {
		width: 100%;
	}
	.mini_contents .email_analysis .select_me {
		margin-top: 15px;
		width: 100%;
	}
	.mini_contents .email_analysis .select_me .select-btn {
		width: 100%;
	}
}

@media (min-width: 700px) {
	.hamburger {
		display: none;
	}
	.columns {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.columns .grid {
		width: 48.5%;
	}
	.wrap_set {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.wrap_set .ff_set {
		width: 48%;
	}
}

@media (min-width: 767px) {
	.fourcard {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.fourcard .grid {
		width: 48%;
	}
}

@media (max-width: 1024px) {
	.scrollable_table {
		overflow-x: auto;
		overflow-y: hidden;
	}
	.scrollable_table table {
		width: 1200px;
	}
}

@media (min-width: 1024px) {
	.fourcard .grid {
		width: 32.4%;
	}
	.columns {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: justify;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.columns .grid {
		width: 32.5%;
	}
}

@media (min-width: 800px) {
	.analysis_data {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.analysis_data .contents {
		border-bottom: 0;
		width: 48%;
		padding: 20px;
		margin-bottom: 30px;
		border: 1px solid #ccc;
		border-radius: 5px;
	}
	.analysis_data #border {
		border: 1px solid #ccc;
	}
}

@media (min-width: 1200px) {
	.analysis_data {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 15px 0;
	}
	.analysis_data .contents {
		border-bottom: 0;
		width: 25%;
		padding: 0 15px;
		margin-bottom: 0px;
		border: 0;
		border-right: 1px solid #ccc;
		border-radius: 0px;
	}
	.analysis_data #border {
		border: 0;
	}
	.columns {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: justify;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.columns .grid {
		width: 24%;
	}
	.fourcard .grid {
		width: 24%;
	}
}

@media (max-width: 700px) {
	.control_top {
		margin-top: 120px;
	}
}

@media (min-width: 1340px) {
	.columns {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: justify;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.columns .grid {
		width: 19%;
	}
}
